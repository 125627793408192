/* eslint-disable no-undef */
/* eslint-disable max-len */
export default class EnvironmentService {
    static getEnvironmentString() {
        const env = global.origin;

        let envString;
        switch (env) {
        case "http://localhost:3000":
            envString = "local";
            break;
        case "https://whlchoices.whl-sandbox.tivity.health":
            envString = "sandbox";
            break;
        case "https://whlchoices.whl-dev.tivity.health":
            envString = "development";
            break;
        case "https://whlchoices.whl-qa.tivity.health":
            envString = "qa";
            break;
        case "https://uat.whlchoices.com":
            envString = "uat";
            break;
        case "https://www.whlchoices.com":
        case "https://whlchoices.com":
            envString = "production";
            break;
        default:
            envString = "development";
        }
        return envString;
    }

    static getEnvironmentVariable(key) {
        const env = this.getEnvironmentString();

        const variables = {
            "local" : {
                "IDENTITY_API": "https://whl-dev.dev-tivityhealth.auth0app.com",
                "CHOICES_API": "https://localhost:7152",
                "REDIRECT_URI": "http://localhost:3000/oauth/authorize",
                "OAUTH_AUDIENCE": "https://whlchoices-auth.whl-dev.tivity.health",
                "OAUTH_UI_CLIENT_ID": "hhBpIoQXKEUbKU8qCSG7JZOoebySteGA",
                "OAUTH_DOMAIN": "https://auth0.whl-dev.tivity.health",
                "CHOICES_URL": "https://localhost:3000",
            },
            "sandbox" : {
                "IDENTITY_API": "https://whl-sandbox.dev-tivityhealth.auth0app.com",
                "CHOICES_API": "https://api.whlchoices.whl-sandbox.tivity.health",
                "REDIRECT_URI": "https://whlchoices.whl-sandbox.tivity.health/oauth/authorize",
                "OAUTH_AUDIENCE": "https://whlchoices-auth.whl-sandbox.tivity.health",
                "OAUTH_UI_CLIENT_ID": "0OyDGLu2jQSPh4slLQ1Cg7G9DsTRWevp",
                "OAUTH_DOMAIN": "https://auth0.whl-sandbox.tivity.health",
                "CHOICES_URL": "https://whlchoices.whl-sandbox.tivity.health",
            },
            "development" : {
                "IDENTITY_API" : "https://whl-dev.dev-tivityhealth.auth0app.com",
                "CHOICES_API": "https://api.whlchoices.whl-dev.tivity.health",
                "REDIRECT_URI": "https://whlchoices.whl-dev.tivity.health/oauth/authorize",
                "OAUTH_AUDIENCE": "https://whlchoices-auth.whl-dev.tivity.health",
                "OAUTH_UI_CLIENT_ID": "hhBpIoQXKEUbKU8qCSG7JZOoebySteGA",
                "OAUTH_DOMAIN": "https://auth0.whl-dev.tivity.health",
                "CHOICES_URL": "https://whlchoices.whl-dev.tivity.health",
            },
            "qa" : {
                "IDENTITY_API": "https://whl-qat.dev-tivityhealth.auth0app.com",
                "CHOICES_API": "https://api.whlchoices.whl-qa.tivity.health",
                "REDIRECT_URI": "https://whlchoices.whl-qa.tivity.health/oauth/authorize",
                "OAUTH_AUDIENCE": "https://whlchoices-auth.whl-qa.tivity.health",
                "OAUTH_UI_CLIENT_ID": "LBK0unXNtxE26goRcUiG7Sp2NrQlHA78",
                "OAUTH_DOMAIN": "https://auth0.whl-qa.tivity.health",
                "CHOICES_URL": "https://whlchoices.whl-qa.tivity.health",
            },
            "uat" : {
                "IDENTITY_API": "https://whl-uat.tivityhealth.auth0app.com",
                "CHOICES_API": "https://api.uat.whlchoices.com",
                "REDIRECT_URI": "https://uat.whlchoices.com/oauth/authorize",
                "OAUTH_AUDIENCE": "https://whlchoices-auth.whl-uat.tivityhealth.com",
                "OAUTH_UI_CLIENT_ID": "wiTUypH2JCE3cAfGo1eWn83S92lFfKiq",
                "OAUTH_DOMAIN": "https://auth0.uat.wholehealthliving.com",
                "CHOICES_URL": "https://uat.whlchoices.com",
            },
            "production" : {
                "IDENTITY_API": "https://whl-prod.tivityhealth.auth0app.com",
                "CHOICES_API": "https://api.whlchoices.com",
                "REDIRECT_URI": "https://www.whlchoices.com/oauth/authorize",
                "OAUTH_AUDIENCE": "https://whlchoices-auth.tivityhealth.com",
                "OAUTH_UI_CLIENT_ID": "PFRRV4VBYmoQ19281UtFBkLqiJpoLeES",
                "OAUTH_DOMAIN": "https://auth0.wholehealthliving.com",
                "CHOICES_URL": "https://www.whlchoices.com",
            },
        };

        return variables[env][key];
    }

    static getConfigurationVariable(key) {
        const configVars = {
            "GOOGLE_JS_API_KEY": "AIzaSyAlg211tsuakJqtFkxWH6bgkwDXtoQC9oI",
            "WELL_BEING_MARKETPLACE":"health partners,summa care, UCLA, concordia",
            "ECOMMERCE_CLIENT_NAMES":"BCBS SC,BCBS AZ,Florida Blue,BCBS NC,BCBS TN,FEP",
            "ECOMMERCE_CLIENT_URLS":"https://bcbsa.southcarolinamp.tivityhealth.com,https://bcbsa.arizonamp.tivityhealth.com,https://bcbsa.floridabluemp.tivityhealth.com,https://bcbsa.northcarolinamp.tivityhealth.com,https://bcbsa.tenessemp.tivityhealth.com,https://bcbsa.fepmp.tivityhealth.com",
            "ECOMMERCE_CLIENT_URL_SLUGS":"bcbs-sc,bcbs-az,bcbs-flblue,bcbs-nc,bcbs-tn,bcbs-fep",
            "BCBS_AZ_REFERRAL_URL":"https://bcbsa.arizonamp.tivityhealth.com",
            "BCBS_TN_REFERRAL_URL": "https://bcbsa.tenessemp.tivityhealth.com",
            "BCBS_FL_REFERRAL_URL": "https://bcbsa.floridabluemp.tivityhealth.com"
        };
        return configVars[key];
    }
}