import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const useAxiosApiClient = (isAuthRequired) => {
    const apiClient = axios.create({
        headers: {
            "Content-Type": "application/json",
        },
    });

    const {getAccessTokenSilently} = useAuth0();
    apiClient.interceptors.request.use(async (config) => {
        try{
            if(isAuthRequired){
                const token = await getAccessTokenSilently();
                config.headers.Authorization = `bearer ${token}`;
            }
        } catch (error) {
            console.error("Error Getting Token");
            // JAMESTODO: Need to make a dev logging.
            // console.error(`Error getting token for request to ${config.url}`);
            // console.trace("Token retrieval trace");
        }
        return config;
    });

    return apiClient;
};

export default useAxiosApiClient;