import UpdateUserDto from '../dto/UpdateUserDto';
import EnvironmentService from "./environment.service";

export default class OAuthService {
    static async getAuthorizationCode(loginHint) {
        const identityApiBaseUri = EnvironmentService.getEnvironmentVariable("OAUTH_DOMAIN");
        const redirectUri = EnvironmentService.getEnvironmentVariable("REDIRECT_URI");

        const identityApiAuthorizeEndpoint = new URL(identityApiBaseUri + "/oauth/authorize");
        
        identityApiAuthorizeEndpoint.searchParams.append('response_type', "code");
        identityApiAuthorizeEndpoint.searchParams.append('client_id', 
            EnvironmentService.getEnvironmentVariable("OAUTH_UI_CLIENT_ID"));
        identityApiAuthorizeEndpoint.searchParams.append('redirect_uri', redirectUri);
        identityApiAuthorizeEndpoint.searchParams.append('login_hint', loginHint);

        return await fetch(identityApiAuthorizeEndpoint.toString(),{ method: "POST" });
    }

    static verifyReferralSource() {
        const { referringClientId, referringDomainId, referringDomainUrl } = this.getReferralData();
        // Checks if both the id values have been set and that they are integers, checks for valid domainUrl
        return Number.isSafeInteger(referringClientId) && Number.isSafeInteger(referringDomainId) 
                && referringDomainUrl && referringDomainUrl.toString().includes('http');
    }

    static getReferralData() {
        return {
            referringClientId: parseInt(sessionStorage.getItem("choices-referring-clientId")),
            referringDomainId: parseInt(sessionStorage.getItem("choices-referring-domainId")),
            referringDomainUrl: sessionStorage.getItem("choices-referring-domainUrl")
        };
    }
}

