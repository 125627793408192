/* eslint-disable max-len */
/* eslint-disable indent */
import validator from "validator";
import {
    invalidCharacters,
    invalidFieldLength,
    invalidRegisterCharacters,
} from "../components/form/Form";
import AccountErrors from "../models/accountErrors";
import RegistrationErrors from "../models/registrationErrors";
import PasswordMatchStates from "../models/passwordMatchStates";
import EnvironmentService from "./environment.service";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import useAxiosApiClient from "../components/shared/useAxiosApiClient";

export default class AccountService {
    static async createAuth0User(user, axiosApiClient) {
        const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");

        const body = {
            connection: 'choices',
            email: user.email.toLowerCase(),
            password: user.password,
            given_name: user.firstName,
            family_name: user.lastName,
            user_metadata: {
                client_id: user.clientId,
                domain_id: user.domainId,
                domain_url: user.domain_url,
                state: user.state,
                is_subscribed: user.isSubscribed
            },
            verify_email: false
        };
        return await axiosApiClient.post(
            baseApiUrl + `/api/user/create`,
            body,
            {  }
        );
    }

    static async editAuth0User(email, firstName, lastName, state, axiosApiClient) {
        const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");

        const userId = localStorage.getItem('choices-userId');
        const body = {
            email,
            given_name: firstName,
            family_name: lastName,
            user_metadata: {
                state: state
            }
        };
        return await axiosApiClient.put(
            baseApiUrl + `/api/user/${userId}`,
            body,
            {  }
        );
    }

    static async subscribe(status, axiosApiClient) {
        const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
        const userId = localStorage.getItem('choices-userId');
        const body = {
            user_metadata: {
                is_Subscribed: status
            }
        };
        return await axiosApiClient.put(
            baseApiUrl + `/api/user/${userId}`,
            body,
            {  }
        );
    }

    static async updatePassword(password, axiosApiClient) {
        const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");
        const userId = localStorage.getItem('choices-userId');
        const body = { password };
        return await axiosApiClient.put(
            baseApiUrl + `/api/user/${userId}`,
            body,
            {  }
        );
    }

    static async getAuth0UserDetails(userId) {
        const axiosApiClient = useAxiosApiClient(true);
        const baseApiUrl = EnvironmentService.getEnvironmentVariable("CHOICES_API");

        return await axiosApiClient.get(baseApiUrl + `/api/user/${userId}`,
            {  });
    }

    static getAccountUpdateRules(
        account,
        firstName,
        lastName,
        references,
        state
    ) {
        return [
            {
                boolComparison:
                    firstName.length <= 0 ||
                    firstName.length > 90 ||
                    /[^a-zA-Z-' ]+$/.test(firstName),
                errorMsg: AccountErrors.FIRST_NAME_ERR,
                inputReference: references.firstName,
            },
            {
                boolComparison:
                    lastName.length <= 0 ||
                    lastName.length > 90 ||
                    /[^a-zA-Z-' ]+$/.test(lastName),
                errorMsg: AccountErrors.LAST_NAME_ERR,
                inputReference: references.lastName,
            },
            {
                boolComparison:
                    account.accountEmail && !validator.isEmail(account.accountEmail),
                errorMsg: AccountErrors.EMAIL_ERR,
                inputReference: references.email,
            },
            {
                boolComparison: state === "",
                errorMsg: AccountErrors.STATE_ERR,
                inputReference: references.state,
            },
            {
                boolComparison:
                    account.oldPassword &&
                    account.oldPasswordMatch !== PasswordMatchStates.VERIFIED_MATCH,
                errorMsg: AccountErrors.OLDPASS_ERR,
                inputReference: references.oldPass,
            },
            {
                boolComparison:
                    account.newPassword &&
                    !this.enforcePasswordRules(account.newPassword),
                errorMsg: AccountErrors.NEWPASS_ERR,
                inputReference: references.newPass,
            },
            {
                boolComparison: account.newPassword && !account.newPasswordMatch,
                errorMsg: AccountErrors.CONFIRMPASS_ERR,
                inputReference: references.confirmPass,
            },
            {
                boolComparison:
                    (account.newPassword && !account.oldPassword) ||
                    (!account.newPassword && account.oldPassword),
                errorMsg: AccountErrors.PASS_ERR,
                inputReference: references.oldPass,
            },
            {
                boolComparison:
                    account.oldPassword && account.newPassword === account.oldPassword,
                errorMsg: AccountErrors.SAMEPASS_ERR,
                inputReference: references.newPass,
            },
        ];
    }

    static getRegistrationRules(register) {
        return [
            {
                boolComparison:
                    invalidFieldLength(register.firstName, 90) ||
                    invalidRegisterCharacters(register.firstName) ||
                    /[^a-zA-Z-' ]+$/.test(register.firstName || ""),
                errorMsg: RegistrationErrors.FIRST_NAME_ERR,
                inputReference: null,
            },
            {
                boolComparison:
                    invalidFieldLength(register.lastName, 90) ||
                    invalidRegisterCharacters(register.lastName) ||
                    /[^a-zA-Z-' ]+$/.test(register.lastName || ""),
                errorMsg: RegistrationErrors.LAST_NAME_ERR,
                inputReference: null,
            },
            {
                boolComparison: !validator.isEmail(register.email || ""),
                errorMsg: RegistrationErrors.EMAIL_ERR,
                inputReference: null,
            },
            {
                boolComparison:
                    invalidFieldLength(register.state, 4) ||
                    invalidCharacters(register.state),
                errorMsg: RegistrationErrors.STATE_ERR,
                inputReference: null,
            },
            {
                boolComparison: register.state === "OR"
                    && (sessionStorage.getItem('choices-referring-domainUrl') === "https://my.bankerslife.com/"
                        || sessionStorage.getItem('choices-referring-domainUrl') === "https://uat-my.bankerslife.com"
                        || sessionStorage.getItem('choices-referring-domainUrl') === "https://genhealthy.com/extra-perks"),
                errorMsg: RegistrationErrors.NO_OREGON_STATE_ERR,
                inputReference: null,
            },
            {
                boolComparison: !this.enforcePasswordRules(register.password),
                errorMsg: RegistrationErrors.PASS_ERR,
                inputReference: null,
            },
            {
                boolComparison: register.confirmPassword !== register.password,
                errorMsg: RegistrationErrors.CONFIRMPASS_ERR,
                inputReference: null,
            },
        ];
    }

    static violatesBusinessRules(ruleArray, action = null) {
        let isBusinessRuleViolation = false;

        ruleArray.forEach((rule) => {
            if (rule.boolComparison) {
                if (action) action(rule.errorMsg);
                if (rule.inputReference) rule.inputReference.current.focus();
                isBusinessRuleViolation = rule.boolComparison;
            }
        });

        return isBusinessRuleViolation;
    }

    static enforcePasswordRules(newPassword) {
        // 8 chars, has uppercase, lowercase, number chars
        const pattern = new RegExp(
            "^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$"
        );
        return pattern.test(newPassword);
    }

    static nameEmailOrCommunicationPreferenceHasChanged(
        accountEmail,
        communicationPreferenceHasChanged,
        firstName,
        lastName
    ) {
        return (
            communicationPreferenceHasChanged ||
            (accountEmail &&
                (accountEmail !== localStorage.getItem("choices-email") ||
                    firstName !== localStorage.getItem("choices-firstName") ||
                    lastName !== localStorage.getItem("choices-lastName")))
        );
    }

    static passwordHasChanged(oldPassword, newPassword, confirmedPassword) {
        return oldPassword && newPassword && confirmedPassword;
    }

    static getCommunicationConsent() {
        const communicationConsent = JSON.parse(
            localStorage.getItem("choices-communication-consent")
        );
        // Opt the user in if they have not deselected the input checkbox
        return communicationConsent !== null ? communicationConsent : true;
    }

    static isValidEmail(email) {
        return validator.isEmail(email) && email.length <= 90;
    }

    static isSilverSneakersMember() {
        const SILVERSNEAKERS_CLIENT_ID = "6";// Hardcoded SS ClientId
        const userClientId = localStorage.getItem("choices-clientId");
        return (userClientId !== null && userClientId === SILVERSNEAKERS_CLIENT_ID);
    }

    //static async subscribe(email, isSubscribed) {
    //  let subscriptionEndpoint = `${EnvironmentService.getEnvironmentVariable(
    //    "IDENTITY_API"
    //  )}/api/accounts/subscribe/${email}/${isSubscribed}`;
    //  return await axios.get(subscriptionEndpoint).catch((error) => error);
    //}

    static isLooseNullOrUndefined(value) {
        // eslint-disable-next-line eqeqeq
        return (
            value === undefined ||
            value === null ||
            value === "null" ||
            value === "undefinied"
        );
    }
}
