import React from 'react';
import { ReactComponent as SearchIcon } from "../../assets/icons/icon-search.svg";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from './SearchBar.module.scss';

const SearchBar = ({ linkTo }) => {
    const [searchData, setSearchData] = React.useState({
        searchInput: "",
    });

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const handleChange = (event) => {
        setSearchData(prevSearch => {
            return {
                ...prevSearch,
                [event.target.name]: event.target.value
            };
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // as this project is currently on hold and de-prioritized, here is a note for my return:
        // going to make an api call using searchParams, useSearchParams to get params from the url.
        // Currently needs to be updated or moved to handleChange where it can pickup all the changes
        // live and not be lagged behind with each click. Only making the api call once submitted.
        // Add styled links to pick up the change and passed to api call if a link is clicked.
        //
        let params = event.target;
        setSearchParams(Object.fromEntries([...searchParams]));

        if (searchData.searchInput !== "") {
            navigate(`/resource-center/search?query=${searchData.searchInput}`);
            setSearchData(prevSearch => ({ searchInput: "" }));
            window.history.replaceState({}, document.title);
        }
    };

    return (
        <div className={styles.searchInputContainer}>
            <form onSubmit={handleSubmit} className={styles.searchForm}>
                <input
                    type="text"
                    className={styles.searchInput}
                    id="searchBox"
                    onChange={handleChange}
                    name="searchInput"
                    value={searchData.searchInput}
                />
                <SearchIcon
                    className={styles.inputSearchIcon}
                />
                <button className={styles.searchBtn}>Search</button>
            </form>
        </div>
    );
};

export default SearchBar;
