/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import styles from './HomePage.module.scss';
import { useNavigate } from "react-router-dom";
import Logo from "../../components/logo/Logo";
import ProductTypeId from "../../models/productTypeId";
import {
    useGetClientByIdQuery,
    useGetFeaturedProductsQuery,
    useGetUserSubscriptionDetailsQuery,
} from '../../services/react-query/choices.service';
import Loading from '../../components/loading/Loading';
import AccountService from "../../services/account.service";
import VisibilityFilter from '../../components/client-filter/VisibilityFilter';
import FeaturedOffersHero from "../../components/offer/featured-offers/FeaturedOffersHero";
import Layout from "../../components/layout/Layout";
import StateResidenceModal from "../../components/stateOfResidence/StateResidenceModal";
import EnvironmentService from "../../services/environment.service";
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';

const HomePage = () => {
    const { data: featuredProductData, error: featuredProductsError, isLoading: isGettingFeaturedProducts } =
        useGetFeaturedProductsQuery(localStorage.getItem("choices-clientId"));

    const { data: clientData, error: clientError, isLoading: isGettingClient } =
        useGetClientByIdQuery(localStorage.getItem("choices-clientId"));

    const [featuredProducts, setFeaturedProducts] = useState([]);
    const navigate = useNavigate();

    const [userStateVerified, setUserStateVerified] = useState(false);
    const residenceState = localStorage.getItem("choices-state");

    const { data: userData, error: userError, isLoading: isGettingUser } =
        useGetUserSubscriptionDetailsQuery(localStorage.getItem("choices-email"));

    const referingUrlBSBC = EnvironmentService
        .getConfigurationVariable("ECOMMERCE_CLIENT_URLS")
        .indexOf(window.sessionStorage['choices-referring-domainUrl']
            ?.toLowerCase()) > -1 ? true : false;

    const isBcbsMember = EnvironmentService
        .getConfigurationVariable("ECOMMERCE_CLIENT_NAMES")
        .indexOf(localStorage
            .getItem('choices-client-name')) > -1;
    const WellBeingMarketplaceClients = EnvironmentService
        .getConfigurationVariable("WELL_BEING_MARKETPLACE")
        .toLowerCase();

    const isLoading = isGettingFeaturedProducts || (isBcbsMember && isGettingUser);

    useEffect(() => {
        if (residenceState !== null && residenceState !== "null" && residenceState !== undefined) {
            setUserStateVerified(true);
        }
    }, [residenceState]);

    useEffect(() => {
        // Cache the client name for use in Google Analytics reporting
        if (clientData && !clientError) localStorage.setItem("choices-client-name", clientData.name);

        // Set the featured products state
        // If the member is a SilverSneakers member, restrict them to only see Vendors
        if (AccountService.isSilverSneakersMember() && featuredProductData)
            setFeaturedProducts(featuredProductData.filter(item => item.productTypeId === ProductTypeId.VENDOR));
        else if (featuredProductData) setFeaturedProducts(featuredProductData);
    }, [featuredProductData]);

    const getWelcomeName = () => {
        const firstName = localStorage.getItem('choices-firstName');
        const lastName = localStorage.getItem('choices-lastName');
        const email = localStorage.getItem('choices-email');
        if ((!firstName || !lastName) && !email) return "check out what's new";
        if (!firstName || !lastName) return `${email}`;
        return `${firstName} ${lastName}`;
    };

    useEffect(() => {     
        if (isBcbsMember && !isGettingUser) {
            if (userData === undefined || userData?.subscriptionStatus === false) {
                navigate("/subscribe");
            }
        }
    }, [userData, isBcbsMember, isGettingUser]);

    return (
        <>
            {
                !userStateVerified &&
                <StateResidenceModal
                    userStateVerified={userStateVerified}
                    setUserStateVerified={setUserStateVerified}
                />
            }
            <Layout pageTitle={'Home'} isLoading={isLoading}>
                <div className={styles.homeContainer}>
                    <div className={styles.featuredOffersContainer}>
                        {!featuredProducts || featuredProducts.length < 1 ? undefined :
                            <FeaturedOffersHero items={featuredProducts} />}
                    </div>
                    <section className={styles.contentContainer}>
                        <p className={styles.welcomeMessage}>
                            Welcome, <span className={styles.welcomeMessageUser}>{getWelcomeName()}</span>!
                        </p>
                        <h1 className={styles.title}>Find Your Discounts</h1>
                        {clientData && WellBeingMarketplaceClients.indexOf(clientData.name.toLowerCase()) < 0 &&
                            <VisibilityFilter>
                                <div
                                    className={`${styles.linkToSearchPage} ${styles.brightBlue}`}
                                    onClick={() => navigate("/practitioners")}
                                >
                                    Practitioners
                                </div>
                            </VisibilityFilter>
                        }
                        <div
                            className={`${styles.linkToSearchPage} ${styles.blue}`}
                            onClick={() => navigate("/products")}
                        >
                            Products & Services
                        </div>
                        {clientData && WellBeingMarketplaceClients
                            .indexOf(clientData.name.toLowerCase()) < 0 && (referingUrlBSBC ?
                                <></> :
                                <VisibilityFilter>
                                    <div
                                        className={`${styles.linkToSearchPage} ${styles.fadedBlue}`}
                                        onClick={() => navigate("/fitness-centers")}
                                    >
                                        Fitness & Wellness Centers
                                    </div>
                                </VisibilityFilter>
                            )}
                        <div
                            className={`${styles.linkToSearchPage} ${styles.darkBlue}`}
                            onClick={() => navigate("/recent-activity")}
                        >
                            Recent Activity
                        </div>
                    </section>
                    <Logo />
                    <Loading isFullScreen={true} isLoading={isLoading} whiteBackground={true} />
                </div>
            </Layout>
        </>
    );
};

export default withAuthenticationRequired(HomePage, {
    onRedirecting: () => <div><Loading isFullScreen={true} isLoading={true} whiteBackground={true} /></div>,
});
