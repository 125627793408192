export default class ProductTypeId {
    static get ANY() {
        return 0;
    }
    static get PROVIDER() {
        // Tab: Practitioners
        return 1;
    }
    static get FACILITY() {
        // Tab: Fitness & Wellness center
        return 2;
    }
    static get VENDOR() {
        // Tab: Products & Services
        return 3;
    }
}