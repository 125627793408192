import React, { useState, useEffect } from 'react';
import styles from './StateOfResidenceInput.module.scss';
import { useChoicesContext } from "../../context/ChoicesContext";
import { useUpdateUserMutation } from '../../services/react-query/identity.service';
import AccountService from "../../services/account.service";
import UpdateUserDto from "../../dto/UpdateUserDto";
import ActionButton from "../action-button/ActionButton";
import StateOfResidenceInput from './StateOfResidenceInput';
import LogoMark from './LogoMark';
import useAxiosApiClient from "../shared/useAxiosApiClient";

const StateResidenceModal = ({
    userStateVerified,
    setUserStateVerified,
}) => {
    const { mutateAsync: updateUser } = useUpdateUserMutation();
    const { firstName, lastName, state, setState } = useChoicesContext();
    const [stateName, setStateName] = useState("");
    const [stateAbbreviation, setStateAbbreviation] = useState("");
    const axiosApiClient = useAxiosApiClient(true);

    useEffect(() => {
        if (stateAbbreviation) {
            setState(stateAbbreviation);
        }
    }, [userStateVerified, stateName, stateAbbreviation]);


    const handleSubmit = async (event) => {
        event.preventDefault();

        const accountEmail = localStorage.getItem('choices-email');
        const request = await AccountService.editAuth0User(
            accountEmail, firstName, lastName, state, axiosApiClient).catch(error => error);

        setUserStateVerified(true);
    };

    return (
        <div className={styles.modal}>
            <div className={styles.modalContainer}>

                <div className={styles.modalHeaderContainer}>
                    <h1 className={styles.modalHeader}>
                        To help us serve you better, {firstName}, please fill out the form below:
                    </h1>
                </div>

                <div className={styles.modalFormContainer}>
                    <form className={styles.modalForm} data-testid="formOne">
                        <StateOfResidenceInput
                            stateName={stateName}
                            setStateName={setStateName}
                            stateAbbreviation={stateAbbreviation}
                            setStateAbbreviation={setStateAbbreviation}
                            placeHolder={"Select your state here"}
                        />

                        <div className={stateAbbreviation
                            ? styles.modalButtonComplete : styles.modalButtonIncomplete} data-testid="buttonOne"
                        >
                            <ActionButton
                                btnType="submit"
                                onClick={handleSubmit}
                            >
                                Done
                            </ActionButton>
                        </div>

                    </form>
                </div>
                <LogoMark />
            </div>
        </div>
    );
};

export default StateResidenceModal;
