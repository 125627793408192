import React, { useEffect, useRef, useState } from "react";
import styles from './AccountForm.module.scss';
import UpdatePassword from "./UpdatePassword";
import NameInput from "./NameInput";
import SubmitUpdate from "./SubmitUpdate";
import EmailInput from "./EmailInput";
import StateOfResidenceInput from '../stateOfResidence/StateOfResidenceInput';
import Loading from "../loading/Loading";
import SubmitBanner from "./SubmitBanner";
import AccountService from "../../services/account.service";
import PasswordMatchStates from '../../models/passwordMatchStates';
import { useChoicesContext } from "../../context/ChoicesContext";
import useAxiosApiClient from "../shared/useAxiosApiClient";

const AccountForm = ({
    clearErrorMessages,
    isError,
    addErrorMessage,
    isSuccess,
    errorMessages,
    setIsError,
    setIsSuccess
}) => {
    const { firstName, lastName, setFirstName, setLastName, state, setState } = useChoicesContext();
    const [accountEmail, setAccountEmail] = useState("");
    const [stateAbbreviation, setStateAbbreviation] = useState(state);
    const [stateName, setStateName] = useState(null);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmedPassword, setConfirmedPassword] = useState("");
    const [newPasswordMatch, setNewPasswordMatch] = useState(false);
    const [oldPasswordMatch, setOldPasswordMatch] = useState(PasswordMatchStates.UNVERIFIED);
    const [isLoading, setIsLoading] = useState(false);
    const axiosApiClient = useAxiosApiClient(true);

    useEffect(() => {
        if (stateAbbreviation) {
            setState(stateAbbreviation);
        }
    }, [stateName, stateAbbreviation]);

    useEffect(() => {
        if (!firstName) setFirstName(localStorage.getItem('choices-firstName'));
        if (!lastName) setLastName(localStorage.getItem('choices-lastName'));
        if (!accountEmail) setAccountEmail(localStorage.getItem('choices-email'));
        if (!stateAbbreviation) setStateAbbreviation(localStorage.getItem('choices-state'));
    }, []);

    const resetFormInputs = () => {
        setOldPassword("");
        setNewPassword("");
        setConfirmedPassword("");
        setNewPasswordMatch(false);
        setOldPasswordMatch(PasswordMatchStates.UNVERIFIED);
    };

    const references = {
        email: useRef(),
        state: useRef(),
        oldPass: useRef(),
        newPass: useRef(),
        confirmPass: useRef(),
        firstName: useRef(),
        lastName: useRef(),
    };

    const validateForm = () => {
        const accountUpdateRules = AccountService.getAccountUpdateRules(
            {
                accountEmail,
                state: stateAbbreviation,
                oldPassword,
                oldPasswordMatch,
                newPassword,
                newPasswordMatch
            },
            firstName,
            lastName,
            references
        );

        if (AccountService.violatesBusinessRules(accountUpdateRules, addErrorMessage)) {
            setIsError(true);
            return false;
        }
        return true;
    };

    const updateAccount = async () => {
        await AccountService.editAuth0User(accountEmail, firstName, lastName, state,axiosApiClient);

        // Update password if it has changed
        if (AccountService.passwordHasChanged(oldPassword, newPassword, confirmedPassword)) {
            await AccountService.updatePassword(newPassword, axiosApiClient);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        clearErrorMessages();
        setIsLoading(true);

        if (!validateForm()) {
            setIsLoading(false);
            return;
        }

        try {
            await updateAccount();
            setIsSuccess(true);
        } catch (error) {
            console.error(error);
            setIsError(true);
        } finally {
            setIsLoading(false);
            resetFormInputs();
        }
    };

    return (
        <form className={styles.formContainer} onSubmit={handleSubmit}>
            <SubmitBanner
                isError={isError}
                isSuccess={isSuccess}
                errorMessages={errorMessages}
                setIsError={setIsError}
                setIsSuccess={setIsSuccess}
            />
            <NameInput
                firstNameRef={references.firstName}
                lastNameRef={references.lastName}
                onClick={() => setIsError(false)}
                clearErrorMessages={clearErrorMessages}
            />
            <EmailInput
                accountEmail={accountEmail}
                setAccountEmail={setAccountEmail}
                emailRef={references.email}
                setIsError={setIsError}
            />
            <StateOfResidenceInput
                stateName={stateName}
                setStateName={setStateName}
                stateAbbreviation={stateAbbreviation}
                setStateAbbreviation={setStateAbbreviation}
            />
            <section className={styles.accountOptions}>
                <UpdatePassword
                    oldPasswordReference={references.oldPass}
                    newPasswordReference={references.newPass}
                    confirmPasswordReference={references.confirmPass}
                    onClick={() => setIsError(false)}
                    accountEmail={accountEmail}
                    confirmedPassword={confirmedPassword}
                    oldPassword={oldPassword}
                    oldPasswordMatch={oldPasswordMatch}
                    newPassword={newPassword}
                    newPasswordMatch={newPasswordMatch}
                    setIsError={setIsError}
                    setNewPassword={setNewPassword}
                    setNewPasswordMatch={setNewPasswordMatch}
                    setConfirmedPassword={setConfirmedPassword}
                    setOldPassword={setOldPassword}
                    setOldPasswordMatch={setOldPasswordMatch}
                />
                <SubmitUpdate />
            </section>
            <Loading isFullScreen isLoading={isLoading} />
        </form>
    );
};

export default AccountForm;
