import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import AccountService from '../../services/account.service';
import useAxiosApiClient from "../../components/shared/useAxiosApiClient";

const Communication = () => {
    const [communicationParams] = useSearchParams();
    const [message, setMessage] = useState('Loading...');
    const axiosApiClient = useAxiosApiClient(true);

    useEffect(async () => {
        const subscriberStatus = Object.fromEntries([...communicationParams]);
        let email = subscriberStatus.email;
        let subscribe = JSON.parse(subscriberStatus.subscribe || null);

        if(subscribe === null || !email)
            return setMessage(<Navigate to="/not-found"/>);
        
        const subscribeEvent = await AccountService.subscribe(subscribe, axiosApiClient).catch(err => err);
        if(subscribeEvent.status !== 200) return setMessage("Attempt failed. Please try again.");
        // eslint-disable-next-line max-len
        else setMessage(`You have been successfully ${subscribe? "added to" : "removed from"} our communication list.`);

    }, [communicationParams,message, axiosApiClient]);
    
    return (
        <Layout pageTitle={'Change Communication Preferences'}>
            {/* Using CSS-in-JS here as style requirements are minimal. */}
            <div style={{height:"84vh",width:"100vw",display:"flex",justifyContent:"center"}}>
                <p style={{
                    marginTop:"max(9rem,18vw)", 
                    fontSize: "max(1.5rem,1.5vw)", 
                    textAlign: "center", 
                    maxWidth:"70%"
                }}>
                    {message}
                </p>
            </div>
        </Layout>
    );
};

export default Communication;